import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'

const Page = ({location,...props}) => {
    const page = get(props, 'data.contentfulPage')
    return ( <div className="container--narrow">
        <h1>
            {page.title}
        </h1>
        <div
            dangerouslySetInnerHTML={{
            __html: page.body.childMarkdownRemark.html,
            }}
        />
    </div> );
}

export default Page;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`